import { render, staticRenderFns } from "./NavigationAccordion.vue?vue&type=template&id=5e37890b&scoped=true&"
import script from "./NavigationAccordion.vue?vue&type=script&lang=js&"
export * from "./NavigationAccordion.vue?vue&type=script&lang=js&"
import style0 from "./NavigationAccordion.vue?vue&type=style&index=0&id=5e37890b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e37890b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationContent: require('/usr/src/web-www/components/organisms/navigation-content/NavigationContent.vue').default,AccordionItem: require('/usr/src/web-www/components/atoms/accordion/AccordionItem.vue').default,Accordion: require('/usr/src/web-www/components/atoms/accordion/Accordion.vue').default})
