//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseText',
  props: {
    tag: {
      type: String,
      default: 'p',
      validator(value) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p'].includes(
          value
        );
      },
    },
    variant: {
      type: String,
      default: 'body-regular',
      validator(value) {
        return [
          'h1',
          'h2',
          'h3',
          'h4-medium',
          'h4-regular',
          'h5-medium',
          'h5-regular',
          'pullquote-longform',
          'body-medium',
          'body-regular',
          'body-serif',
          'micro-header',
          'meta',
          'micro-body-medium',
          'micro-body-regular',
          'nav-text-medium',
        ].includes(value);
      },
    },
  },
  methods: {
    textClass(variant) {
      switch (variant) {
        case 'h1':
          return 'typography-heading-1';
        case 'h2':
          return 'typography-heading-2';
        case 'h3':
          return 'typography-heading-3';
        case 'h4-medium':
          return 'typography-heading-4';
        case 'h4-regular':
          return 'typography-heading-4-regular';
        case 'h5-medium':
          return 'typography-heading-5';
        case 'h5-regular':
          return 'typography-heading-5-regular';
        case 'pullquote-longform':
          return 'pullquote-longform';
        case 'micro-header':
          return 'typography-micro-header';
        default:
          return variant;
      }
    },
  },
};
