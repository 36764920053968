//
//
//
//
//
//

import { isScrollBehaviorSupported } from '@/utils/feature-detection/is-scroll-behavior-supported';
import { prefersReducedMotion } from '@/utils/media/prefers-reduced-motion';

export const itemStateChangeEvent = 'Accordion: item state change';
export const closeAllEvent = 'Accordion: close all';
export const translateItemEvent = 'Accordion: translate item';
export const accordionItemAttributeName = 'data-accordion-item';
export const openAccordionItemAttributeName = 'data-open-accordion-item';

export default {
  name: 'Accordion',
  props: {
    multi: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      additionalHeight: 0,
    };
  },
  computed: {
    cHeight() {
      return {
        height: `calc(100% + ${this.additionalHeight}px)`,
      };
    },
  },
  mounted() {
    this.$root.$on(
      itemStateChangeEvent,
      (isItemOpen, itemId, contentPanelHeight) => {
        this.closeAllItems(isItemOpen, itemId);
        this.emitToParent(isItemOpen, itemId);
        this.translateItems(isItemOpen, itemId, contentPanelHeight);
        this.scroll(isItemOpen, itemId);
        this.adjustHeight(isItemOpen, itemId);
      }
    );
  },
  methods: {
    /**
     * @param {boolean} isItemOpen
     * @param {number} itemId
     */
    closeAllItems(isItemOpen, itemId) {
      if (isItemOpen && !this.$props.multi) {
        this.$root.$emit(closeAllEvent, itemId);
      }
    },
    /**
     * @param {boolean} isItemOpen
     * @param {number} itemId
     */
    emitToParent(isItemOpen, itemId) {
      if (isItemOpen) {
        this.$emit('open', itemId);
      } else {
        this.$emit('close', itemId);
      }
    },
    /**
     * @param {number} itemId
     * @param {boolean} isItemOpen
     * @param {number} contentPanelHeight
     */
    translateItems(isItemOpen, itemId, contentPanelHeight) {
      // wrapping in a nextTick, because the classes haven't been updated
      // in response to the item state change yet
      this.$nextTick(function () {
        /**
         * @type {Element}
         */
        const accordionElement = this.$refs.accordion;
        if (!accordionElement) return;

        const openedItemElement = this.getAccordionItemElement(itemId);

        const allItems = accordionElement.querySelectorAll(
          `[${openAccordionItemAttributeName}]`
        );

        const openedItemIndex = Array.from(allItems).findIndex(
          (el) => el === openedItemElement
        );
        // want to only translate items that appear *after* the
        // one that has been opened
        const itemsToTranslate = Array.from(allItems).slice(
          openedItemIndex + 1
        );

        itemsToTranslate.forEach((el) => {
          this.$root.$emit(
            translateItemEvent,
            Number(el.getAttribute(accordionItemAttributeName)),
            isItemOpen ? contentPanelHeight : -contentPanelHeight
          );
        });
      });
    },
    /**
     * @param {number} itemId
     */
    getAccordionItemElement(itemId) {
      /**
       * @type {Element}
       */
      const accordionElement = this.$refs.accordion;
      if (!accordionElement) return;

      return accordionElement.querySelector(
        `[${accordionItemAttributeName}="${itemId}"]`
      );
    },
    /**
     * When item is opened - scroll it into view
     * When item is closed - scroll to the top
     * of the accordion
     *
     * @param {boolean} isItemOpen
     * @param {number} itemId
     */
    scroll(isItemOpen, itemId) {
      /**
       * if smooth scroll behavior not supported
       * by the browser or user prefers
       * reduced motion, then don't perform the scroll
       */
      if (!isScrollBehaviorSupported() || prefersReducedMotion()) return;

      const e = this.getAccordionItemElement(itemId);
      if (isItemOpen) {
        /**
         * nextTick to ensure that the element is scrolled, once
         * animations have been kicked off
         */
        this.$nextTick(() => {
          e?.scrollIntoView({
            top: 10,
            behavior: 'smooth',
          });
        });
      } else {
        this.$refs.accordion.scrollIntoView({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    /**
     * Adjust the height of the accordion container
     * so that we have room to scroll, so that the
     * opened accordion item is at the top of the
     * scrollable area.
     *
     * @param {boolean} isItemOpen
     * @param {number} itemId
     */
    adjustHeight(isItemOpen, itemId) {
      if (!isItemOpen) {
        this.additionalHeight = 0;
      }

      const e = this.getAccordionItemElement(itemId);
      const accordionStartValue = this.$refs.accordion.offsetTop;
      const openedElementStartValue = e?.offsetTop ?? 0;

      const additionalHeight = openedElementStartValue - accordionStartValue;
      if (additionalHeight) {
        this.additionalHeight = additionalHeight;
      }
    },
  },
};
