import { render, staticRenderFns } from "./NavigationCard.vue?vue&type=template&id=44c4f4c8&scoped=true&"
import script from "./NavigationCard.vue?vue&type=script&lang=js&"
export * from "./NavigationCard.vue?vue&type=script&lang=js&"
import style0 from "./NavigationCard.vue?vue&type=style&index=0&id=44c4f4c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c4f4c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,BaseText: require('/usr/src/web-www/components/atoms/base-text/BaseText.vue').default})
