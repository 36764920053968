
export default {
  name: 'BaseLink',
  props: {
    path: {
      type: String,
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
    noFollow: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preserveQuery: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component() {
      return (
        this.$props.disabled 
          ? 'span'
          : this.$props.external
          ? 'a'
          : 'nuxt-link'
      );
    },
  },

  render(createElement) {
    const attrs = {};
    const props = {};
    const defaultClasses = 'link';

    if (this.component === 'nuxt-link') {
      props.to = this.$props.preserveQuery
        ? { path: this.$props.path, query: this.$route.query }
        : this.$props.path;
    } else if (!this.$props.disabled) {
      attrs.href = this.$props.path;
      attrs.target = this.$props.target;
      attrs.rel = this.$props.noFollow ? 'nofollow noreferrer noopener' : null;
    }

    return createElement(
      this.component,
      {
        attrs,
        props,
        class: defaultClasses,
        ...(this.component === 'nuxt-link' && {
          nativeOn: {
            ...this.$listeners,
          },
        }),
        ...((this.component === 'a' || this.component === 'span') && {
          on: { ...this.$listeners },
        }),
      },
      this.$slots.default
    );
  },
};
