import { render, staticRenderFns } from "./NavigationContent.vue?vue&type=template&id=4c7f1184&scoped=true&"
import script from "./NavigationContent.vue?vue&type=script&lang=js&"
export * from "./NavigationContent.vue?vue&type=script&lang=js&"
import style0 from "./NavigationContent.vue?vue&type=style&index=0&id=4c7f1184&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7f1184",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseText: require('/usr/src/web-www/components/atoms/base-text/BaseText.vue').default,SubMenuItem: require('/usr/src/web-www/components/atoms/menu-bar/SubMenuItem.vue').default,DescriptionSection: require('/usr/src/web-www/components/molecules/navigation/description-section/DescriptionSection.vue').default,CardSection: require('/usr/src/web-www/components/molecules/navigation/card-section/CardSection.vue').default,LargeIconListSection: require('/usr/src/web-www/components/molecules/navigation/large-icon-list-section/LargeIconListSection.vue').default,SmallIconListSection: require('/usr/src/web-www/components/molecules/navigation/small-icon-list-section/SmallIconListSection.vue').default,BaseIcon: require('/usr/src/web-www/components/atoms/base-icon/BaseIcon.vue').default,SubMenu: require('/usr/src/web-www/components/atoms/menu-bar/SubMenu.vue').default})
