//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import footerNavQuery from '@/apollo/queries/footer-navigation/footerNavigation.gql';
import SiteBanner from '../components/organisms/site-banner/SiteBanner.vue';
import HeaderDefault from '../components/organisms/header-default/HeaderDefault.vue';
import FooterDefault from '../components/organisms/footer-default/FooterDefault.vue';

export default {
  name: 'App',
  components: {
    HeaderDefault,
    FooterDefault,
    SiteBanner,
  },
  data: () => ({
    footerNavigation: [],
  }),
  async fetch() {
    try {
      const client = this.$apolloProvider.defaultClient;
      const { data } = await client.query({
        query: footerNavQuery,
      });

      if (data?.footerNavigation?.FooterNavigationColumns) {
        this.footerNavigation = data?.footerNavigation?.FooterNavigationColumns;
      }
    } catch {}
  },

  computed: {
    ...mapState({
      drawerOpen: (state) => state.navigation.drawerOpen,
      pageTopOffset: (state) => state.global.pageTopOffset,
    }),
  },
  created() {
    this.$fetch();
  },
};
