//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MenuBar',
  data() {
    return {
      openedChildren: {},
      childrenPopperStyles: {},
      childrenArrowStyles: {},
      isBackgroundOpen: false,
      openedChildSize: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    cIsSomeMenuItemActive() {
      return Object.values(this.openedChildren).find(Boolean);
    },
    cOpenedIndex() {
      return Object.entries(this.openedChildren).find(
        ([, isBackgroundOpen]) => isBackgroundOpen
      )?.[0];
    },
    cStyle() {
      return {
        width: this.openedChildSize.width + 'px',
        height: this.openedChildSize.height + 'px',
        ...this.childrenPopperStyles[this.cOpenedIndex],
      };
    },
    cArrowStyle() {
      return {
        ...this.childrenArrowStyles[this.cOpenedIndex],
      };
    },
  },
  mounted() {
    this.$root.$on(MENU_ITEM_OPEN_EVENT, (index, menuItemRef) => {
      this.openedChildren = { ...this.openedChildren, [index]: true };

      this.openedChildSize = {
        width: menuItemRef.offsetWidth,
        height: menuItemRef.offsetHeight,
      };

      this.isBackgroundOpen = true;
    });
    this.$root.$on(MENU_ITEM_CLOSE_EVENT, (index) => {
      this.openedChildren = { ...this.openedChildren, [index]: false };
      if (!this.cIsSomeMenuItemActive) {
        this.isBackgroundOpen = false;
      }
    });

    this.$root.$on(
      MENU_ITEM_POPPER_STYLE_CHANGE_EVENT,
      (index, style, arrowStyle) => {
        this.childrenPopperStyles = {
          ...this.childrenPopperStyles,
          [index]: style,
        };
        this.childrenArrowStyles = {
          ...this.childrenArrowStyles,
          [index]: arrowStyle,
        };
      }
    );
  },
  methods: {
    /**
     * handling keydown events that originated in the menu
     * @param {KeyboardEvent} event
     */
    handleMenuKeydown(event) {
      // validate that the event originated on the correct child element
      // only handling events that originate on menu items, on the first menu level (not a submenu)
      /**
       * @type {Element}
       */
      const target = event.target;
      if (
        target.getAttribute('role') !== 'menuitem' ||
        target.getAttribute(MENU_LEVEL_ATTRIBUTE_NAME) !== '0'
      ) {
        return;
      }

      const targetMenuItemIndexString = target.getAttribute(
        MENU_ITEM_INDEX_ATTRIBUTE_NAME
      );

      if (!targetMenuItemIndexString) return;

      const targetMenuItemIndex = Number(targetMenuItemIndexString);

      if (event.key === 'ArrowRight') {
        this.handleArrowRight(targetMenuItemIndex);
        event.preventDefault();
      }

      if (event.key === 'ArrowLeft') {
        this.handleArrowLeft(targetMenuItemIndex);
        event.preventDefault();
      }

      if (event.key === 'Home') {
        this.handleHome();
        event.preventDefault();
      }
      if (event.key === 'End') {
        this.handleEnd();
        event.preventDefault();
      }
    },
    /**
     * @param {number} eventTargetIndex
     */
    handleArrowRight(eventTargetIndex) {
      // move to the next menu item
      if (eventTargetIndex > this.$refs.menu.children.length - 1) return;

      this.focusMenuItemWithIndex(eventTargetIndex + 1);
    },
    /**
     * @param {number} eventTargetIndex
     */
    handleArrowLeft(eventTargetIndex) {
      // move to the previous menu item
      if (eventTargetIndex === 0) return;

      this.focusMenuItemWithIndex(eventTargetIndex - 1);
    },
    handleHome() {
      // move to first item
      this.focusMenuItemWithIndex(0);
    },
    handleEnd() {
      // move to last item
      this.focusMenuItemWithIndex(this.$refs.menu.children.length - 1);
    },
    /**
     * @param {number} index
     */
    focusMenuItemWithIndex(index) {
      this.$refs.menu.querySelector(getMenuItemSelector(index, 0))?.focus();
    },
  },
};

export const MENU_LEVEL_ATTRIBUTE_NAME = `data-menu-level`;
export const MENU_ITEM_INDEX_ATTRIBUTE_NAME = `data-menu-item-index`;
export const MENU_ITEM_HOVER_EVENT = 'Menu: item hover';
export const MENU_ITEM_OPEN_EVENT = 'Menu: item open';
export const MENU_ITEM_CLOSE_EVENT = 'Menu: item close';
export const MENU_ITEM_POPPER_STYLE_CHANGE_EVENT =
  'Menu: item popper style change';

/**
 * @param {number | string} level
 */
export const getMenuLevelAttributePair = (level) => ({
  [MENU_LEVEL_ATTRIBUTE_NAME]: level,
});

/**
 * @param {number | string} index
 */
export const getMenuItemIndexAttributePair = (index) => ({
  [MENU_ITEM_INDEX_ATTRIBUTE_NAME]: index,
});

/**
 * @param {number} index
 * @param {number} level
 */
export const getMenuItemSelector = (index, level) =>
  `[role="menuitem"][${MENU_ITEM_INDEX_ATTRIBUTE_NAME}="${index}"][${MENU_LEVEL_ATTRIBUTE_NAME}="${level}"]`;

/**
 * @param {number} index
 * @param {number} level
 */
export const getSubMenuSelector = (index, level) =>
  `[role="menu"][${MENU_ITEM_INDEX_ATTRIBUTE_NAME}="${index}"][${MENU_LEVEL_ATTRIBUTE_NAME}="${level}"]`;
